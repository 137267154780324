
.cta-banner {
  border-radius: 5px;
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.red-yellow {
    background-color: @brand-red;

    .title {
      color: @brand-yellow;
      margin-bottom: 10px;
      font-family: inherit;
      font-size: 24px;

      .gibson-bold;
    }

    p {
      margin: 0;
    }

    button {
      margin: 0;
      color: @brand-black;
      width: 100%;

      @media all and (min-width: 992px) {
        float: right;
      }
    }
  }

  /* two columns setup */
  .first {
    margin-bottom: 40px;
  }

  button {
    width: 100%;
  }

  @media all and (min-width: 1185px) {
    flex-direction: row;

    .first {
      flex-grow: 3;
      margin-bottom: 0;
    }
  }
}
