@import "variables";
.panic-bar {
    top: 0;
    width: calc(100% ~"-" @side-menu-width);
    min-height: 30px;
    background-color: @brand-white;
    color: @dark-grey;
    position: fixed;
    z-index: 19;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border: 1px solid @darkness-grey;
    .red-btn-play-responsibly {
        color: @brand-yellow;
        font-family: "League Gothic", sans-serif;
        font-weight: 400;
        font-size: 13px;
        padding: 1px 14px;
        margin: 4px 10px;
        span {
            float: right;
            margin: 1px -5px 0px 0px;
        }
    }
    .black-btn-play-responsibly {
        color: @brand-white;
        font-family: "League Gothic", sans-serif;
        font-weight: 400;
        font-size: 13px;
        padding: 1px 13px;
        margin: 3px 0px;
        background: @brand-black;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        text-align: center;
        text-decoration: none;
        border-radius: var(--button-border-radius);
        display: flex;
        align-items: center;
        span {
            margin: 1px -5px 0px 0px;
        }
    }
    &.popup {
        background-color: inherit;
        border: none;
        width: inherit;
        min-height: 30px;
    }
}

.session-timer {
    padding: 0;
    margin-right: 25px;
    margin-left: 5px;
    font-size: 12px;
    text-transform: uppercase;
    .timer {
        min-width: 95px;
        display: inline-block;
    }
}

.panic-bar-desc {
    font-size: 13px;
    margin-left: 30px;
    a {
        text-decoration: underline;
    }
}

.panic-buttons {
    margin: 2px 25px;
    display: flex;
    &.popup {
        margin: 2px -5px;
    }
}

.panic-button {
    height: 21px;
    margin: 2px 5px;
    cursor: pointer;
}

.panic-button-r {
    height: 17px;
    margin: 0px 6px 0px -9px;
}

.panic-bar-margin {
    margin-top: 30px !important;
    .mudal-dialog header {
        margin-top: -20px;
    }
}

.gv-buttons-margin {
    margin-top: 20px;
}

.panic-self-exclusion-btns {
    display: flex;
    button:first-child {
        margin-right: 16px;
    }
}

@media (max-width: 939px) {
    .panic-bar-desc,
    .red-btn-play-responsibly {
        display: none !important;
    }
}

@media (max-width: @menu-switch-breakpoint) {
    .panic-bar {
        width: 100%;
        justify-content: center;
        flex-direction: column;
        min-height: 50px;
        left: 0;
    }
    .session-timer {
        margin-right: 0;
        .timer {
            width: auto;
            min-width: 90px;
        }
    }
    .panic-bar-margin {
        margin-top: 50px !important;
    }
    .gv-buttons-margin {
        margin-top: 0;
    }
    .panic-buttons {
        width: 337px;
        display: flex;
        justify-content: space-evenly;
    }
}
