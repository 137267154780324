@green:  #009a39;
@triggerMixin : 'triggers';
@croatiaMixin : 'OSMCroatia';
@triggerAdMixin : 'rg-ad';
.theme (@type) when (@type = 'triggers'){
    #trigger-content a {
        color: @green
        }

        #trigger-content{
            height: 300px;
            overflow-y: auto;
            &::-webkit-scrollbar-thumb {
                background: #8D8D8D;
                border-radius: 20px;
            }
            
            &::-webkit-scrollbar-track {
                background: #333333;
                border-radius: 20px;
            }
            
            &::-webkit-scrollbar {
                display: block;
                width: 4px;
            }
        }
        
        .btn-fieldset{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding: 0px !important;
            word-wrap: break-word;
            a{
                color: @green;
            }
        }

        .close-button{
            display: none;
        }
        .close-button-no-reload{
            display: none;
        }
        
        .btn{
            padding: 15px 0;
            width: 90% !important;
            background: @green;
        }

        @media only screen and (min-width: 768px){
            .btn-fieldset{
                justify-content: space-around;
                flex-direction: row;
                padding: 25px !important;
            }

            .btn{
                width: 45% !important;
            }
        }
  }
  
  .rgtrigger {
    .theme(@triggerMixin);
  }

.theme (@type) when (@type = 'OSMCroatia'){
    #trigger-content a {
        color: @green
        }

        
        .btn-fieldset{
            .cta-two{
                display: none;
            }
        }
        .btn{
            width: 90% !important;
        }
        .close-button{
            display: block;
        }
  }
  
  .osm {
    .theme(@croatiaMixin);
  }

.theme (@type) when (@type = 'rg-ad'){
    
    .btn-fieldset{
            .cta-two{
                display: none;
            }
        }
        .btn{
            width: 90% !important;
        }
        .close-button-no-reload{
            display: block;
        }
  }
  
  .ad {
    .theme(@triggerAdMixin);
  }