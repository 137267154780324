@font-face {
    font-family: 'rizk-icons-font';
    src: url('fonts/mobile-nav-icons/rizk-icons-font.eot?bofkzn');
    src: url('fonts/mobile-nav-icons/rizk-icons-font.eot?bofkzn#iefix') format('embedded-opentype'), url('fonts/mobile-nav-icons/rizk-icons-font.ttf?bofkzn') format('truetype'), url('fonts/mobile-nav-icons/rizk-icons-font.woff?bofkzn') format('woff'), url('fonts/mobile-nav-icons/rizk-icons-font.svg?bofkzn#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@keyframes reward-anim {
    0% {
      transform: translate3d(0px,0px,0px);
    }
    30% {
      transform: translate3d(0px,-6px,0px) scale(1.05);
    }
    60% {
      transform: translate3d(0px,0px,0px);
    }
    100% {
      transform: translate3d(0px,0px,0px);
    }
  }
  
  @keyframes promo-anim { 
    0% {
      transform: rotateX(0deg);
    }
    50% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }
  
  @keyframes race-anim {
    9% {
      transform: translate3d(0px,0px,0px);
    }
    10% {
      transform: translate3d(-100px,0px,0px);
    }
    35% {
      transform: translate3d(0px,0px,0px);
    }
  
    100% {
      transform: translate3d(0px,0px,0px);
    }
  }
  
  
  @keyframes wor-spin {
    0% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes chat-spin {
    0% {
      transform: rotateX(0deg);
    }
    50% {
      transform: rotateX(-20deg);
    }
    100% {
      transform: rotateX(0deg);
    }
  }
  
  @keyframes games-anim {
    0% { 
      background-position: 0px;
    }
    100% { 
      background-position: -80px;
    }
  }
  
  @keyframes sports-anim {
    0% { 
      transform: translate3d(0, 0, 0);    
    }
    100% { 
      transform: translate3d(0, -8px, 0); 
    }
  }
  

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'rizk-icons-font' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-account:before {
    content: "\e900";
    margin-left: 1px;
    font-size: 21px;
}

.icon-cashout:before {
    content: "\e901";
    font-size: 27px;
}

.side-menu li:hover .icon-casino {
    animation: promo-anim 0.85s ease 0s infinite;
}

.side-menu li:hover .icon-live-casino {
    animation: race-anim 1.5s ease infinite;
}

.side-menu li:hover .icon-sportsbook-live {
  animation: promo-anim 0.85s ease 0s infinite;
}

.side-menu li:hover .icon-rizk-insider {
  animation: promo-anim 0.85s ease 0s infinite;
}

.side-menu li:hover .icon-rizk-wheel {
    animation: wor-spin 1.8s ease infinite;
}

.side-menu li:hover .icon-live-chat {
    animation: chat-spin 0.5s linear 0s infinite;
    transform-origin: 50% 100%;
}

.side-menu li:hover .icon-rizk-blog {
  animation: chat-spin 0.5s linear 0s infinite;
  transform-origin: 50% 100%;
}

.side-menu li:hover .icon-rewards {
    animation: reward-anim 0.8s linear infinite;
}

.side-menu li:hover .icon-rizk-race{
    animation: race-anim 1.5s ease infinite;
}

.side-menu li:hover .icon-sportsbook {
    animation: sports-anim .7s ease 0s infinite;
}

.side-menu li:hover .icon-inbox {
  animation: race-anim 1.2s ease infinite;
}

.side-menu li:hover .icon-raffles {
  animation: wor-spin 1.8s ease infinite;
}

.icon-casino:before {
    content: "\e902";
    font-weight: bold;
}

.icon-safer-gambling:before {
    content: "\e911";
    font-weight: bold;
}

.icon-safer-gambling {
    width: 13%;
}

.icon-equaliser:before {
    content: "\e912";
    font-weight: bold;
}

.icon-rizk-blog:before {
    content: "\e913";
    font-weight: bold;
}

.icon-live-bet:before {
    content: "\e903";
}

.icon-live-casino:before {
    content: "\e904";
}

.icon-live-chat:before {
    content: "\e905";
}

.icon-menu:before {
    content: "\e906";
    font-size: 20px;
}

.icon-menu-top:before {
  content: "\e928";
  font-size: 25px;
}

.icon-my-bets:before {
    content: "\e907";
}

.icon-rewards:before {
    content: "\e908";
    font-weight: bold;
}

.icon-rizk-insider:before {
    content: "\e909";
    font-size:23px;
}

.inkabet-insider:before{
  content: "\e925";
  font-weight: 900;
}

.icon-rizk-wheel:before {
    content: "\e90b";
}

.rizk-races:before {
    content: "\e90a";
    font-size: 20px;
    letter-spacing: -2px;
    margin-left: -6px;
}

.crash-races:before {
  content: "\e931";
  font-size: 25px;
}

// Update with actual assets when design provide them
.inkabet-races:before {
  content: "\e931";
  font-size: 25px;
}

.betsson-races:before {
  content: "\e931";
  font-size: 25px;
}

.icon-logout:before {
    content: "\e910";
    margin-left: 0px;
}

.icon-inbox:before {
    content: "\e924";
}

.icon-raffles:before{
    content: "\e907";
    font-size: 23px;
}

.inkabet-raffles:before{
  content: "\e929";
}

.crash-raffles:before{
  content: "\e930";
}

.live-sport:before{
  content: "\e927" ;
  font-weight: 900;
}

.icon-sportsbook:before {
  content: "\e90c";
}

@media (min-width: @sb-tablet) {
  .icon-menu:before {
      font-size: 15px;
  }

  .icon-raffles:before{
      font-size: 26px;
  }
}

.icon-question-mark:before {
  content: url('/assets/img/icons/question-mark.svg');
}

.icon-person:before {
  content: url('/assets/img/icons/icon-user.svg');
}
