.rizk-notification {
  position: fixed;
  bottom:0;
  transform: translate3d(0,90px,0);
  width: calc(100% ~"-" @side-menu-width);
  left: @side-menu-width;
  text-align: center;
  font-size: 16px;
  background: var(--tertiary-color);
  color: @brand-black;
  z-index: 40;
  transition: all 0.4s ease-in;

  &.show-notification {
    transform: translate3d(0,0,0);
  }

  &-dismiss {
    position: absolute;
    top: 6px;
    right: 6px;
    line-height: 10px;
    font-size: 24px;

    &:hover {
      color: inherit;
      text-decoration: none;
    }

  }

  .rizk-notification-message a {
    text-decoration: underline;
  }

  @media screen and (max-width: @menu-switch-breakpoint) {
    left: 0;
    width:100%;
    z-index: 40;
    &-dismiss{
      top:100vh;
    }
  }
}

.horizontal-toggle .rizk-notification{
  width: 100%;
  left:0;
}