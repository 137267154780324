#rizk-footer-new {
  min-height: 100px;
  overflow: hidden;
  text-align: center;
  padding: 18px 0px;
  @media (max-width: @menu-switch-breakpoint) {
    padding-bottom: 36px;
  }
  transition: transform 0.5s ease-in-out;
  background-color: @insider-background-single-article;
  .flags {
    display: inline-block;
    width: 32px;
    height: 32px !important;
    opacity: 0.6;
    &.fi {
      background: url('/assets/img/flags/FINLAND.svg') no-repeat;
      background-size: cover;
    }
    &.no {
      background: url('/assets/img/flags/NORWAY.svg') no-repeat;
      background-size: cover;
    }
    &.se {
      background: url('/assets/img/flags/SWEDEN.svg') no-repeat;
      background-size: cover;
    }
    &.de {
      background: url('/assets/img/flags/GERMANY.svg') no-repeat;
      background-size: cover;
    }
    &.uk {
      background: url('/assets/img/flags/ENGLAND.svg') no-repeat;
      background-size: cover;
    }
    &.nz {
      background: url('/assets/img/flags/NEW-ZEALAND.svg') no-repeat;
      background-size: cover;
    }
    &.ca {
      background: url('/assets/img/flags/CANADA.svg') no-repeat;
      background-size: cover;
    }
    &.pl {
      background: url('/assets/img/flags/POLAND.svg') no-repeat;
      background-size: cover;
    }
    &.in {
      background: url('/assets/img/flags/INTERNATIONAL.svg') no-repeat;
      background-size: cover;
    }
    &.international {
      background: url('/assets/img/flags/INTERNATIONAL.svg') no-repeat;
      background-size: cover;
    }
  }
  .language-menu {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    width: 200px;
    > a {
      margin: 5px 10px 5px 0px;
      &:hover {
        .flags {
          opacity: 1;
        }
      }
    }
    .flag-checked {
      opacity: 1;
    }
  }
  .footer-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    li {
      margin: 5px 10px;
      background-position: center;
      height: 28px;
    }
  }
  .visa-logo {
    background: url('/assets/img/footer/VISA.svg') no-repeat;
    width: 60px;
  } // .interac-logo {
  //     width: 53px;
  //     background-position: -105px -90px;
  //     &:hover {
  //         background-position: -105px 156px;
  //     }
  // }
  .master-logo {
    background: url('/assets/img/footer/MASTERCARD.svg') no-repeat;
    width: 40px;
  }
  .paypal-logo {
    background: url('/assets/img/footer/PAYPAL.svg') no-repeat;
    width: 89px;
  } // .bank-transfer-logo {
  //     width: 60px;
  //     background-position: -252px -82px;
  //     &:hover {
  //         background-position: -252px 159px;
  //     }
  // }
  // .tsi-logo {
  //     width: 44px;
  //     background-position: -159px -26px;
  //     &:hover {
  //         background-position: -159px 210px;
  //     }
  // }
  .skrill-logo {
    background: url('/assets/img/footer/SKRILL.svg') no-repeat;
    width: 57px;
  }
  .neteller-logo {
    background: url('/assets/img/footer/NETELLER.svg') no-repeat;
    width: 102px;
  } // .trustly-logo {
  //     width: 77px;
  //     background-position: -449px -29px;
  //     &:hover {
  //         background-position: -449px 209px;
  //     }
  // }
  .euteller-logo {
    background: url('/assets/img/footer/EUTELLER.svg') no-repeat;
    width: 108px;
  } // .netent-logo {
  //     width: 89px;
  //     background-position: 0px -93px;
  //     &:hover {
  //         background-position: 0px 161px;
  //     }
  // }
  // .comodo-logo {
  //     width: 53px;
  //     background-position: -312px 125px;
  //     &:hover {
  //         background-position: -312px 155px;
  //     }
  // }
  // .rng-logo {
  //     width: 44px;
  //     background-position: -368px 112px;
  //     &:hover {
  //         background-position: -368px 153px;
  //     }
  // }
  // .ideal-logo {
  //     width: 46px;
  //     margin-left: 7px;
  //     background-position: 0px 0px;
  // }
  .sofort-logo {
    background: url('/assets/img/footer/SOFORT.svg') no-repeat;
    width: 85px;
  }
  .zimpler-logo {
    background: url('/assets/img/footer/ZIMPLER.svg') no-repeat;
    width: 101px;
  }
  .footer-logos-2 {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    span {
      margin: 5px 10px;
      display: inline-block;
      height: 40px;
      background-position: center;
    }
  }
  .casinomeister {
    background: url('/assets/img/footer/CASINOMEISTER.svg') no-repeat;
    width: 102px;
  }
  .lcb {
    background: url('/assets/img/footer/LCB.svg') no-repeat;
    width: 110px;
  }
  .facebook {
    background: url('/assets/img/footer/FACEBOOK.svg') no-repeat;
    width: 22px;
  }
  .twitter {
    background: url('/assets/img/footer/TWITTER.svg') no-repeat;
    width: 27px;
  }
  .instagram {
    background: url('/assets/img/footer/INSTAGRAM.svg') no-repeat;
    width: 25px;
  }
  .mga {
    background: url('/assets/img/footer/MGA.svg') no-repeat;
    width: 139px;
  } // .lotteriinspektionen {
  //     width: 58px;
  //     background-position: -354px 0;
  // }
  .curacao {
    background: url('/assets/img/footer/CURACAO-EGAMING.svg') no-repeat;
    width: 29px;
  }
  .footer-logos-3 {
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
    span {
      margin: 5px 12px;
      background-position: center;
      display: inline-block;
      height: 35px;
    }
  }
  .odr-logo {
    background: url('/assets/img/footer/footer-logo-odr.svg') no-repeat;
    width: 68px;
  }
  .gam-care-logo {
    background: url('/assets/img/footer/GAMCARE.svg') no-repeat;
    width: 139px;
  }
  .gam-anon-uk-irl-logo {
    width: 148px;
    background-position: -62px 0px;
  }

  .plus-18-logo-pe {
    background: url('/assets/img/footer/plus_18_pe.svg') no-repeat;
    width: 30px;
  }

  .gamble-aware-logo {
    background: url('/assets/img/footer/BEGAMBLEAWARE.svg') no-repeat;
    width: 144px;
  }

  .stodlinjen-logo {
    width: 165px;
    background-position: -1249px 0px;
  }
  .footer-links {
    margin: 0;
    a {
      line-height: 35px;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      color: @brand-white;
      white-space: nowrap;
      &:hover {
        color: var(--tertiary-color);
      }
      &.menu-item-active {
        color: var(--tertiary-color);
      }
    }
  }

  .footer-text {
    display: flex;
    text-align: left;
    p {
      margin: 0px 5px 0px 10px;
    }
    .footer-license {
      color: @brand-white;
      font-size: 12px;
      text-align: justify;
      a {
        color: @brand-white;
        text-decoration: underline;
      }
    }
  }
  .cookie-disclaimer {
    position: fixed;
    bottom: 0%;
    right: 0%;
    z-index: 101;
    background: @dark-grey;
    color: @darkness-grey;
    text-align: center;
    width: 100%;
    overflow: hidden;
    box-shadow: 0 0 5px black;
    padding-bottom: 15px;
    transform: translateY(100%);
    transition: all 0.4s ease-in;
    p {
      margin-bottom: 0;
      & a {
        text-decoration: underline;
        color: @darkness-grey;
      }
      & .disclaimer-button {
        color: @dark-grey;
      }
    }
    &-button {
      margin-left: 10px;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    &-read-more {
      color: @button-yellow;
      background-color: transparent;
      &:hover {
        color: @button-yellow;
        text-decoration: underline;
      }
    }
  }
  .footer-cta {
    display: none;
  } // new footer
  .rizk-footer {
    display: flex;
    span {
      background-position: center;
      display: inline-block;
      height: 40px;
    }
    .links-column {
      text-align: left;
      .footer-links {
        display: flex;
        flex-direction: column;
      }
    }
    .gray-spacer {
      border-bottom: 1px solid @footer-spacer-color;
      margin: 20px 0px;
    }
    .gray-spacer-mobile {
      display: none;
    }
    .comission-logos {
      display: flex;
      li {
        margin: 10px 10px 10px 0px;
      }
    }
    .license-text {
      display: flex;
      align-items: center;
    }
    .footer-copyright-wrap {
      display: flex;
    }
    .footer-copyright {
      text-align: left;
      color: @brand-white;
      font-size: 11px;
    }
    .logos-column,
    .links-column {
      padding: 0px 30px;
    }
  }
  @media (max-width: @menu-switch-breakpoint) {
    .footer-cta {
      display: block;
    }
    footer {
      padding-bottom: @mobile-drawer-menu-height;
    }
    .rizk-footer {
      flex-direction: column;
      align-items: center;
      .links-column {
        text-align: center;
        .footer-links {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-around;
          li {
            margin: 7px;
          }
        }
      }
      .language-menu {
        display: none;
      }
      .gray-spacer-mobile {
        display: block;
      }
      .gray-spacer-desktop {
        display: none;
      }
      .footer-text {
        flex-direction: column;
      }
      .comission-logos {
        width: 100%;
        ul {
          width: 100%;
          display: flex;
          justify-content: space-around;
          margin: 10px 0px;
        }
      }
      .footer-license {
        margin: 0px;
      }
      .footer-copyright {
        width: 75%;
        margin: 0 auto;
        padding-bottom: @mobile-drawer-menu-height;
        .footer-license {
          margin-bottom: 5px;
        }
        p {
          text-align: center;
          font-size: 13px;
        }
      }
      .footer-copyright-wrap {
        flex-direction: column-reverse;
      }
    }
  }
}
