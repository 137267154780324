.has-error .form-control,
.has-success .form-control,
.form-control{
	box-shadow: none;
	&:focus{
		box-shadow: none;
		//border-style: solid;
		//border-width: 2px;
	}
}

