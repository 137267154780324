/* Margin where the hover images are aligned to */
@hover-offset: var(--side-menu-icon-vertical-positiom);
@basic-offset: 0px;

@keyframes reward-anim {
  0% {
    transform: translate3d(0px,0px,0px);
  }
  30% {
    transform: translate3d(0px,-6px,0px) scale(1.05);
  }
  60% {
    transform: translate3d(0px,0px,0px);
  }
  100% {
    transform: translate3d(0px,0px,0px);
  }
}

@keyframes promo-anim { 
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes race-anim {
  9% {
    transform: translate3d(0px,0px,0px);
  }
  10% {
    transform: translate3d(-100px,0px,0px);
  }
  35% {
    transform: translate3d(0px,0px,0px);
  }

  100% {
    transform: translate3d(0px,0px,0px);
  }
}


@keyframes wor-spin {
  0% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes chat-spin {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(-20deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

@keyframes games-anim {
  0% { 
    background-position: 0px;
  }
  100% { 
    background-position: -80px;
  }
}

@keyframes sports-anim {
  0% { 
    transform: translate3d(0, 0, 0);    
  }
  100% { 
    transform: translate3d(0, -8px, 0); 
  }
}

/* Menu icons */
.icon {
  -webkit-transform: translate3d(0px,0px,0px);
  background: url("../img/icons/menu-icons-s.png") no-repeat top center;
  display: block;
  margin: auto;
  background-size: 40px;
  width: 35px;
  height: 20px;

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
    background: url("../img/icons/menu-icons-l.png") no-repeat top center;
    background-size: 40px;
  }

  
  &.safer-gambling {
    background: url("../img/icons/safer-gambling/shield_white.svg") no-repeat top center !important; 

    &:hover {
      animation: chat-spin 0.5s linear 0s infinite;
      transform-origin: 50% 100%;
    }

    .main-menu .active &,
    a:focus &,
    a:active &,
    a:hover & {
      background: url("../img/icons/safer-gambling/shield_yellow.svg") no-repeat top center !important; 
    }
  }

  &.rizk-blog {
    background: url("../img/icons/rizk-blog/Blog-Icon-White.svg") no-repeat top center !important; 

    .side-menu a:hover & {
      animation: chat-spin 0.5s linear 0s infinite;
      transform-origin: 50% 100%;
    }

    .main-menu .active &,
    a:focus &,
    a:active &,
    a:hover & {
      background: url("../img/icons/rizk-blog/Blog-Icon-Yellow.svg") no-repeat top center !important; 
    }
  }

  &.games {
    background-position: -1px -178px;
    width: 18px;
    height: 21px;
    margin-left: 1px;
    
    .side-menu a:hover & {
      animation: promo-anim 0.85s ease 0s infinite;
    }

    .main-menu .active &,
    a:focus &,
    a:active &,
    a:hover & {
      background-position: @hover-offset -178px;
    }
  }

  &.live-casino {
    background-position: 0px -200px;
    
    .side-menu a:hover & {
      animation: race-anim 1.5s ease infinite;
    }

    .main-menu .active &,
    a:focus &,
    a:active &,
    a:hover & {
      background-position: @hover-offset -200px;
    }
  }
 
  &.rewards {
    background-position: @basic-offset -20px;

    .side-menu a:hover & {
      animation: reward-anim 0.8s linear infinite;
    }

    .main-menu .active &,
    a:focus &,
    a:active &,
    a:hover & {
      background-position: @hover-offset -20px;
    }
  } 

  &.live-chat {
    background-position: @basic-offset -60px;
    height: 19px;
    
    .side-menu a:hover & {
      animation: chat-spin 0.5s linear 0s infinite;
      transform-origin: 50% 100%;
    }

    .main-menu .active &,
    a:focus &,
    a:active &,
    a:hover & {
      background-position: @hover-offset -60px;
    }
  }

  &.insider {
    background-position: @basic-offset -40px;

    .side-menu a:hover & {
      animation: promo-anim 0.85s ease 0s infinite;
    }

    .main-menu .active &,
    a:focus &,
    a:active &,
    a:hover & {
      background-position: @hover-offset -40px;
    }
  }

  &.wor {
    background-position: @basic-offset -80px;
    
    .side-menu a:hover & {
      animation: wor-spin 1.8s ease infinite;
    }

    .main-menu .active &,
    a:focus &,
    a:active &,
    a:hover & {
      background-position: @hover-offset -80px;
    }
  }

  &.rizk-race {
    background-position: @basic-offset -100px;

    .side-menu a:hover & {
      animation: race-anim 1.5s ease infinite;
    }

    .main-menu .active &,
    a:focus &,
    a:active &,
    a:hover & {
      background-position: @hover-offset -100px;
    }
  }

  &.menu {
    background-position: @basic-offset -120px;

    a:focus &,
    a:active &,
    a:hover & {
      background-position: @hover-offset -120px;
    }
  }

  &.close-menu {
    width: 15px;
    height: 15px;
    background-position: @basic-offset -120px;

    a:focus &,
    a:active &,
    a:hover & { 
      background-position: @hover-offset -120px;
    }
  }

  &.sports {
    background-position: 0px -221px;
    
    .side-menu a:hover & {
      animation: sports-anim .7s ease 0s infinite;
    }

    .main-menu .active &,
    a:focus &,
    a:active &,
    a:hover & {
      background-position: @hover-offset -221px;
    }
  }

}

.some-icons {
  background: url("../img/icons/some-small.png") no-repeat top center;
  display: block;
  margin: auto;
  background-size: 18px;

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
    background: url("../img/icons/some-large.png") no-repeat top center;
    background-size: 18px;
  }

  &.twitter {
    width: 18px;
    height: 15px;
    background-position: 0px 0px;
  }

  &.instagram {
    width: 18px;
    height: 17px;
    background-position: 0px -16px;
  }

  &.facebook {
    width: 18px;
    height: 17px;
    background-position: 0px -35px;
  }
}

#snapchat-norway {
    max-width: 100px;
    max-height: 100px;
    margin-top: 10px;
}

