@keyframes spinning {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

#rizk-loader {
  top:50%;
  height:200px;
  margin-top:-100px;
  margin-left:-100px;

  img {
    margin-bottom: 10px;
  }
}

.rizk-loader {
  width:200px;
  left:50%;
  position:fixed;
  z-index:9999;
  text-align:center;
  transition: opacity 0.5s ease-in-out;
  
  img {
    animation: spinning 15s linear 0s infinite;
    width:100%;
  }
}

@keyframes ltor {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}


#rizk-loader-small {
  width:100%;
  position:fixed;
  z-index:20;
  height:5px;
  display:none;
  bottom:0px;
  left:0px;
  height:2px;
  >div {
    background: #f6e103;
    background:url("../img/rizk-bar-bg.png");
    background: -webkit-linear-gradient(90deg, #f4921f 0%, #ffff00 100%);
    background: -ms-linear-gradient(90deg, #f4921f 0%, #ffff00 100%);
    background: linear-gradient(90deg, #f4921f 0%, #ffff00 100%);
    height: 2px;
    animation: ltor 6s ease-out 0s infinite;
  }
}

