/* Buttons and such */

input,
button {
  border: 0;
  padding: 8px;
  outline: none;
  color:black;

  .rizk-border-radius;
  
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

select {
  outline:none;
}

//input:focus {
//  border: 1px solid #a1a1a1;
//}

.rizk-btn,
.btn {
  text-transform:uppercase;
  font-weight: 900;
  letter-spacing:0.04em;
  text-align: center;
  color: @brand-black;
  padding:10px;
  font-size: 14px;
  line-height: 14px;
  text-decoration:none;
  transition: transform .1s ease-in-out;
  border-radius: var(--button-border-radius);
  cursor: pointer;

  &.no-border {
    border: transparent;
  }

  &:active {
    transform:scale(0.95);
  }

  &[disabled] {
    opacity: 0.5 !important;
  }

}

.btn-wide {
  width: 100%;
}

.red-btn,
.btn-red {
  background: @button-red;
  color: @brand-white;

  &:hover {
    background: @button-red-hover;
  }
}

.white-btn,
.btn-white {
  background: @button-white;
  color: black;
  cursor: pointer;

  &:hover {
    background: @button-white-hover;
  }

}

.btn-black, .btn-secondary {
  background: var(--secondary-bg-button);
  color: var(--quaternary-color-button);
  cursor: pointer;

  &:hover {
    background-color: var(--secondary-bg-button-hover);
  }

}

.yellow-btn,
.btn-yellow, .btn--primary {
  background: var(--primary-button-bg);
  color: var(--main-btn-color);
  cursor: pointer;

  &:hover {
    background: var(--primary-button-bg-hover);
  }
}

.btn-transparent{
  background: transparent;
  color: @pages-content;
  border: 2px solid @pages-content;
  transition: all 0.3s ease 0s;
  &:hover {
    background: var(--tertiary-color);
    color: @brand-black;
    border: 2px solid @brand-black;
  }
}

.green-btn,
.btn-green {
  background: var(--form-user-submit-bg);
  color: @brand-white;
  line-height: 14px;
  cursor: pointer;
  padding: 15px;

  &:hover, &:focus {
    filter: brightness(1.1);
    transition: 0.2s;
  }
}

.btn-continue{
  background: var(--continue-btn-bg);
  padding: 14px;
  margin: 0px 0 20px;
}

.btn-light-grey {
  background: #8080805c;
  color: #fff;
  line-height: 14px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  &:hover {
    background: #9998985c;
    scale: .98;
  }
}

.btn-white-border {
  color: white !important;
  font-weight: 100;
  border-radius: var(--button-border-radius);
  height: 100%;
  align-content: center;
  margin-bottom: 0;
  background: none;
  border: 1px solid white;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: #dad9d95c;
    scale: .98;
  }
}

.blue-btn {
  background: @button-blue;
  color: @brand-white;
  cursor: pointer;
  &:hover {
    background: @button-blue-hover;
  }
}

.btn-inactive {
  background: transparent;
  color: @brand-white;
  padding: 8px 14px;

  &:hover {
    color: @brand-white;
    background: transparent;
  }
}

.btn-clear{
  background:var(--races-bg-button);
  color:var(--races-color-button);
}

.btn-inactive-lighter {
  @inactive-color: rgba(255,255,255,0.6);
  border: 1px solid @inactive-color;
  color: @inactive-color;
  padding: 8px 14px;

  &:hover {
    color: darken(@inactive-color, 20%);
    border-color: darken(@inactive-color, 20%);
  }
}

a.rizk-btn {
  display: inline-block;
  cursor: pointer;
  font-weight: 900;

  &.btn-wide {
    margin-bottom: 16px;
  }
}

.btn-large {
  font-size: 28px;
  min-width: 300px;
  cursor: pointer;
}

/* GameView buttons */

.btn {
  &--yellow {
    background-color: #ffe501;
    color: black;
    width: 100%;
    font-size: 18px;
    padding: 15px;
    cursor: pointer;
  }
}

button,
html input[type="button"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

.btn-main{
  background: var(--primary-button-bg) !important;
  color: var(--registerTxt-side);
}

.btn-sub,.btn-side{
  background: var( --loginBg-side);
  color: var(--loginTxt-side) !important;
  border: var(--loginBorder-side, none);

  &:hover {
    background: var(--loginBG-hover-side);
  }
}

.btn-agecheck {
  padding: 10px 20px;
  color: black;
  margin: 5px 0;
  display: inline-block;
}
