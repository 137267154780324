@import 'breakpoints';

.promotions {
  background-color: var(--body-background-color);

  &__wrapper {
    width: 100%;
    margin: auto;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0;
      color: var(--primary-color);
      font-size: 28px;
    }

    a {
      color: var(--tertiary-color);
      font-size: 16px;
      cursor: pointer;
      font-weight: 300;
      text-transform: capitalize;
    }
  }

  .promotions__title {
    font-family:
      League Gothic,
      League-Gothic-fallback;
    font-size: 32px;
    margin-bottom: 30px;
    text-transform: uppercase;
    line-height: 1.1;
    font-weight: 800;
    letter-spacing: 0.02em;
  }

  .sub-posts {
    display: flex;
    justify-content: space-between;
    min-width: 260px;
    margin-top: 24px;
    gap: 20px;
    overflow-x: scroll;
    scrollbar-width: none;
  }

  .article-sub-post {
    position: relative;
    border-radius: 10px;
    min-width: 80%;
    height: 170px;
    overflow: hidden;

    &__title {
      font-size: 15px;
      margin: 2px 0;
      color: #f0f0f0;
      font-weight: 600;
      letter-spacing: 0.02em;
      line-height: 1.1;
    }

    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .overlay {
      position: absolute;
      bottom: 0;
      background: rgba(0, 0, 0, 0.6);
      color: #f0f0f0;
      padding: 7px 15px;
      width: 100%;

      p {
        margin: 7px 0px;
        font-weight: 300;
        height: 1.85em;
        line-height: 1.3em;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 14px;
      }
    }
  }
}

.promotions {
  .tablet({
        .article-sub-post {
            min-width: 250px;
            height: inherit;
            overflow: hidden;
            max-height: 190px;
        }
    });

  .desktop-retina({
        .article-sub-post {
            min-width: 300px;
            height: inherit;
            overflow: hidden;
            max-height: inherit;
            min-height: 190px;
        }
    });
}

.all-content > section {
  &.promotions {
    padding: 32px 24px 0px;

    .tablet({
      padding: 22px 128px 20px;
    });
  }
}
