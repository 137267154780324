#livechat-compact-container {
  display: none !important;
}

#chat-modal {
  display: none;
  position: fixed;
  border: 0px;
  overflow: hidden;
  width: 450px;
  height: 450px;
  z-index: 999997;
  bottom: 0px;
  right: 0px;
  margin-right: 2px;
  background-color: #F0F0F0;
  iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
  button[name=close] {
    cursor: pointer;
    border-radius: 0;
    padding: 0;
    margin: 0 5px;
    background: #000 url("../img/icons/modal-close-button.svg");
    width: 40px;
    height: 40px;
    opacity: 1.0;
    z-index: 10;
    border-radius: 50%;
    float: left;
    transform: scale(0.6);
  }
}