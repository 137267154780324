@import 'variables';
@import 'highlight-box';
@import 'mixins';
@import 'breakpoints';

#payments {
    li {
      &.visa {
        width: 100px;
        height: 50px;
        background: url('/assets/img/footer/optimised/visa.svg') no-repeat;
      }
  
      &.mc {
        width: 100px;
        height: 50px;
        background: url('/assets/img/footer/optimised/mastercard.svg') no-repeat;
      }
  
      &.trustly {
        width: 100px;
        height: 50px;
        background: url('/assets/img/footer/optimised/trustly.svg') no-repeat !important;
      }
  
      &.neteller {
        width: 100px;
        height: 50px;
        background: url('/assets/img/footer/optimised/neteller.svg') no-repeat !important;
      }
  
      &.paysafe {
        width: 100px;
        height: 50px;
        background: url('/assets/img/footer/optimised/paysafe.svg') no-repeat !important;
      }
  
      &.muchbetter {
        width: 100px;
        height: 50px;
        background: url('/assets/img/footer/optimised/much-better.svg') no-repeat !important;
      }

      &.mifinity {
        width: 100px;
        height: 50px;
        background: url('/assets/img/footer/optimised/mifinity.svg') no-repeat;
      }

      &.neosurf {
        width: 100px;
        height: 50px;
        background: url('/assets/img/footer/optimised/neosurf.svg') no-repeat !important;
      }

      &.siirto {
        width: 100px;
        height: 50px;
        background: url('/assets/img/devcode/siirto.png') no-repeat !important;
      }

      &.siirto {
        width: 100px;
        height: 50px;
        background: url('/assets/img/footer/optimised/siirto.png') no-repeat !important;
        background-size: contain !important;
        background-position-y: center !important;
      }

      &.pwhitelogo {
        width: 60px;
        height: 15px;
        margin: 10px 0 0;

        .tablet({
          width: 100px;
          height: 20px;
          margin: 20px 0 0;
        });

        &.jeton{
          background: url('/assets/img/payments/Jeton.svg') no-repeat center;
          background-size: contain;
        }

       &.luxonPay {
        min-width: 80px;
        background: url('/assets/img/payments/LuxonPay.svg') no-repeat center;
        background-size: contain;
       }
       &.mastercard {
        background: url('/assets/img/payments/Mastercard.svg') no-repeat center;
        background-size: contain;
       }
       &.mifinity {
        background: url('/assets/img/payments/Mifinity.svg') no-repeat center;
        background-size: contain;
       }
       &.visa {
        background: url('/assets/img/payments/Visa.svg') no-repeat center;
        background-size: contain;
       }
      }
    }
  }
  
  .horizontal-toggle #payments ul {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around
}

  .sr{
    .visa{
      background: url('/assets/img/footer/visa.png') no-repeat !important;
      background-size: contain !important;
      background-position-y:center !important;
      width: 68px !important;
      height: 22px !important;
    }
    .mc{
      background: url('/web/assets/img/footer/optimised/mastercard.png') no-repeat !important;
      background-size: contain !important;
      background-position-y: center !important;
      height: 36px !important;
      width: 20px !important;
    }
  }

  @media screen and (max-width: 1300px) {
    .in-card ul {
      flex-wrap: wrap;
    } 
  }