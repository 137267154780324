@register-grey: #4c4c4c;

// @font-face {
//   font-family: 'rizk';
//   src: url('fonts/rizk.eot');
//   src: url('fonts/rizk.eot?#iefix') format('embedded-opentype'),
//        url('fonts/rizk.woff2') format('woff2'),
//        url('fonts/rizk.woff') format('woff'),
//        url('fonts/rizk.ttf') format('truetype'),
//        url('fonts/rizk.svg#@{icon-font-svg-id}') format('svg');
// }

#registration-modal .mudal-dialog {
  max-width: inherit;
}

#btn-validate-mobile,
#btn-continue {
  &:disabled {
    cursor: not-allowed !important;
    color: @brand-white;
    opacity: 0.3 !important;
  }
  &:disabled:active {
    transform: inherit !important;
    opacity: 1 !important;
  }
}

.progress-slogan {
  display: inline-block;
}

.hide-element,
.hide-element::before,
.hide-element::after,
.hide-element * {
  display: none !important;
  visibility: hidden !important;
}

.alert {
  margin: 20px 0 0 0;
  padding: 0;
  background: none;
  border: none;
  color: @brand-red;
  font-size: 17px;
}

.register-progress {
  position: relative;
  float: right;
  width: 40%;
  line-height: 42px;
  padding: 16px 0;
  user-select: none;

  @media screen and (max-width: 620px) {
    float: none;
    width: 100%;
    padding: 0px 0 16px;
  }

  .rizk-progress-bar-container {
    //@media screen and (max-width: 620px) {
    //  width: 93%;
    //}

    .rizk-progress-bar-up {
      border-color: var(--modal-header-bg);
    }

    .rizk-progress-bar-down {
      border-color: var(--modal-header-bg);
    }
  }

  .indicator-container {
    transition: all 0.5s ease-in;
  }

  .indicators {
    display: inline-block;
    margin: auto;
    text-align: center;
    position: absolute;
    top: -10px;
    left: -18px;
    z-index: 20;
    width: 100%;

    .indicator {
      position: absolute;
      top: 2px;

      &.first {
        left: 25%;
      }

      &.second {
        left: 50%;
      }

      &.third {
        left: 75%;
      }

      a {
        display: block;
        width: 30px;
        height: 30px;
        border: 3px solid @brand-white;
        text-align: center;
        line-height: 25px;
        border-radius: 15px;
        font-size: 16px;
        pointer-events: none;
        outline: 0;
        text-decoration: none;
        .gibson-semi-bold;

        &.active {
          background-color: @brand-white;
          color: @brand-black;
          pointer-events: auto;
        }

        &.inactive {
          color: @brand-white;
          background: @brand-black;
          border-color: @brand-black;
        }
      }
    }
  }
}

.register-form,
.modal-de-migration,
.mudal-inkabet-migration {
  position: relative;
  user-select: none;

  p {
    font-size: 16px;
    //margin-bottom: 0;
    //.gibson-light;

    &.terms-disclaimer {
      margin-top: 5px;

      a {
        color: @register-grey;
        text-decoration: underline;
      }
    }
  }

  .help-block {
    ul {
      margin-bottom: 0;
    }
  }

  .btn {
    &.btn-inactive {
      .btn-green;
    }
  }

  .sex {
    label {
      margin: 0 20px;
    }

    .validator-icon {
      display: none !important;
    }

    #form_user_sex {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    @media all and (max-width: 465px) {
      &.mobile-wide {
        .form-group {
          width: 100%;
        }

        label {
          margin-left: 0;
        }
      }
      &.no-padding {
        padding-left: 0px;
      }
    }
  }

  input[type='checkbox'] + label span,
  input[type='radio'] + label span {
    min-width: 24px;
  }

  .confirmation-of-user-actions {
    &__form {
      label {
        max-height: 70px;
        display: flex;
        align-items: center;
      }

      span {
        top: inherit;
      }
    }
  }

  .dob {
    .select-container {
      width: 27%;
      margin-right: 2%;

      &:nth-child(3) {
        width: 42%;
        margin-right: 0;
      }
    }

    & + .glyphicon {
      top: 18px;
    }
  }

  .mobile-confirm {
    margin-bottom: 10px;
  }

  .user-disclaimer-label {
    color: @brand-red;
  }

  .password-wrap {
    position: relative;

    .toggle-password {
      position: absolute;
      top: 0;
      right: 0;
      color: @register-grey;

      &:focus,
      &:active,
      &:hover {
        color: @register-grey;
      }
    }

    .help-block {
      display: none;
    }
  }

  .requirements {
    margin-top: 5px;
  }

  .form-group.has-error + .requirements {
    color: @brand-red;
  }

  .resend {
    margin: 20px 0;

    a {
      color: @register-grey;
      font-size: 18px;
      margin-top: 0;
    }
  }

  .country {
    .select-container {
      width: 100%;
      display: inline-block;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  label.focus {
    color: var(--brand-green);
  }

  .accept-terms {
    color: @modal-paragraph;
    margin-bottom: 5px;
    font-weight: normal;
  }

  .align-labels {
    .form-group {
      label {
        margin: 0 35px 10px 30px;
      }
      span {
        position: absolute;
        right: 0;
        top: -5px;
      }
    }
  }

  .align-labels-last {
    .form-group {
      label {
        margin-left: 30px;
        display: flex;
        align-items: center;
      }
      span {
        margin-left: -30px;
        top: 0;
      }
    }
  }
}

.requirements-title {
  font-weight: 600;
  margin: 10px 0;
  display: block;
  color: var(--primary-color);
}

.pass-forgot {
  a:hover {
    color: var(--primary-color) !important;
  }
  input {
    color: var(--primary-color) !important;
    background: var(--secondary-color) !important;
  }
}

#requirements-list {
  margin-bottom: 15px;
  li {
    span {
      &:before {
        font-family: 'rizk';
        content: '';
        transform: rotate(-90deg);
        display: inline-block;
        margin-right: 5px;
        transition: transform 0.2s;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #7d7d7d;
        transform: rotate(0deg);
      }
      &.satisfied {
        position: relative;
        color: green;
        &:before {
          content: '';
          top: 2px;
          left: 6px;
          width: 6px;
          height: 10px;
          border: solid green;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
          margin-right: 10px;
        }
      }
    }
  }
}

// Force xs to stack
@media screen and (max-width: 780px) {
  .xs5,
  .xs7 {
    clear: both;
    width: 100%;
    padding-left: 0;
  }

  .mobile-prefix-container {
    .select-container {
      width: 100%;
    }
  }
}

.customer-care-disclaimer {
  display: block;
  background: url('../img/livechat.svg') no-repeat top left;
  min-height: 32px;
  line-height: 32px;
  color: @register-grey;
  padding-left: 50px;
}

#form_user_submit {
  .btn-continue;
}

.reg-inner {
  width: 80%;
  margin: 0 auto;
}

#pw-mismatch {
  color: #ef4136;
  display: none;
}

#second .form-group {
  width: 49%;
  display: inline-block;
  &:nth-child(even) {
    padding-left: 16px;
  }

  @media all and (max-width: 465px) {
    &.mobile-wide {
      width: 100%;
      margin-bottom: 10px;
    }
    &.no-padding {
      padding-left: 0px;
    }
  }
}

#third .user-disclaimer label {
  font-weight: normal;
  text-transform: none;
  font-size: 13px;
  line-height: 1.2em;
  display: block;

  span {
    margin-bottom: 23px;
    float: left;
    top: -2px;
  }

  @media screen and (max-width: 980px) {
    span {
      margin-bottom: 24px;
    }
  }
}

@media screen and (max-width: 980px) {
  #reg-gb-disc,
  .banner-column {
    display: none;
  }
  .reg-inner {
    width: 100%;
  }
}

p.regform-small-promo-text {
  color: var(--tertiary-color);
  margin: -15px 0 0 0;
}
@media screen and (min-width: 620px) {
  p.regform-small-promo-text {
    margin: -15px 0 12px 0;
  }
}
@media screen and (min-width: 620px) and (max-width: 700px) {
  p.regform-small-promo-text {
    max-width: 230px;
  }
}
@media screen and (min-width: 801px) and (max-width: 840px) {
  p.regform-small-promo-text {
    max-width: 230px;
  }
}
@media screen and (min-width: 980px) {
  .register-form {
    min-width: 700px;
  }
}
