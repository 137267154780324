@footer-logos-filter: var(--footer-logos-filter);

.betsson {
  .sponsorship-logos-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem 4rem;
    padding: 1rem;
    border-bottom: 1px solid #4a4848;

    a {
      margin: 0.5rem;
      text-decoration: none;
      img {
        max-width: 100%;
        transition: all 0.3s ease;
        width: clamp(180px, 50%, 60px);
        aspect-ratio: 3/1;
      }
    }
  }
  @media (max-width: 480px) {
    .sponsorship-logos-container a img {
      margin: 0.15rem;
      min-width: 130px;
    }
  }

  .payment-logos-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 2rem;
    padding: 1rem;
    border-bottom: 1px solid #4a4848;

    li {
      align-self: center;
      box-sizing: border-box;
      padding: 0 !important;
      background-size: cover;
      background-position: left;

      &:hover {
        background-position: right;
      }
    }

    .visa-logo-optimised {
      background-image: url(/assets/img/footer/betsson-visa.png);
      width: 46px;
      aspect-ratio: 38 / 14;
    }

    .apple-pay-logo {
      background-image: url(/assets/img/footer/betsson-apple-pay.png);
      width: 34px;
      aspect-ratio: 45 / 28;
    }

    .mastercard-logo-optimised {
      background-image: url(/assets/img/footer/betsson-mastercard.png);
      width: 32px;
      aspect-ratio: 32 / 20;
    }

    .paypal-logo {
      background-image: url(/assets/img/footer/betsson-paypal.png);
      width: 74px;
      aspect-ratio: 100 / 27;
    }

    .trustly-logo {
      background-image: url(/assets/img/footer/betsson-trustly.png);
      width: 80px;
      aspect-ratio: 80 / 20;
    }

    .skrill-logo {
      background-image: url(/assets/img/footer/betsson-skrill.png);
      width: 54px;
      aspect-ratio: 54 / 20;
    }

    .neteller-logo-optimised {
      background-image: url(/assets/img/footer/betsson-neteller.png);
      width: 80px;
      aspect-ratio: 100 / 20;
    }

    .bank-transfer-logo-optimised {
      background-image: url(/assets/img/footer/betsson-bank-transfer.png);
      width: 70px;
      aspect-ratio: 64 / 20;
    }

    .comodo-secure {
      background-image: url(/assets/img/footer/betsson-comodo-secure.png);
      width: 52px;
      aspect-ratio: 46 / 28;
    }

    .rng-certified {
      background-image: url(/assets/img/footer/betsson-rng.png);
      width: 36px;
      aspect-ratio: 30 / 20;
    }
  }

  ul.footer-logos3 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    .gamble-aware-logo {
      background-image: url(/assets/img/footer/be-gamble-aware.png);
      background-size: contain;
      width: 191px;
      filter: var(--footer-logos-filter);
    }
    .gambling-commission-logo {
      background-image: url(/assets/img/footer/gambling-commission.png);
      background-size: contain;
      width: 180px;
    }
    .gamstop-logo {
      background-image: url(/assets/img/footer/gam-stop.svg);
      background-size: contain;
      width: 160px;
    }
    .plus-18-logo {
      background-image: url(/assets/img/footer/plus_18_pe.svg);
      background-size: contain;
      width: 70px;
    }
  }
}
