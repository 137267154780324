.mobile(@rules) {
    @media screen and (max-width: 767px) { @rules(); }
  }
.tablet(@rules) {
  @media screen and (min-width: 768px) { @rules(); }
}
.tablet-landscape(@rules) {
  @media screen and (min-width: 999px) { @rules(); }
}
.desktop(@rules) {
  @media screen and (min-width: 1024px) {@rules();}
}

.desktop-large(@rules) {
  @media screen and (min-width: 1129px) {@rules();}
}

.desktop-retina(@rules) {
  @media screen and (min-width: 1370px) {@rules();}
}