//
// Forms
// --------------------------------------------------

// Normalize form controls
//
// While most of our form styles require extra classes, some basic normalization
// is required to ensure optimum display with or without those classes to better
// address browser inconsistencies.

/*input[type="file"] {
  display: block;
}

// Make range inputs behave like textual form controls
input[type="range"] {
  display: block;
  width: 100%;
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
  height: auto;
}*/

// Focus for file, radio, and checkbox
/*input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  .tab-focus();
}*/

// Common form controls
//
// Shared size and type resets for form controls. Apply `.form-control` to any
// of the following form controls:
//
// select
// textarea
// input[type="text"]
// input[type="password"]
// input[type="datetime"]
// input[type="datetime-local"]
// input[type="date"]
// input[type="month"]
// input[type="time"]
// input[type="week"]
// input[type="number"]
// input[type="email"]
// input[type="url"]
// input[type="search"]
// input[type="tel"]
// input[type="color"]

.form-control {
  display: block;
  width: 100%;
  //height: @input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
  //padding: @padding-base-vertical @padding-base-horizontal;
  //font-size: @font-size-base;
  line-height: 14px;
  //color: @input-color;
  //background-color: @input-bg;
  //background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  //.transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");

  // Customize the `:focus` state to imitate native WebKit styles.
  //.form-control-focus();


  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    border: 0;
    background-color: transparent;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    //background-color: @input-bg-disabled;
    opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
  }

  // &[disabled],
  // fieldset[disabled] & {
  //   cursor: @cursor-disabled;
  // }

  // Reset height for `textarea`s
  /*textarea& {
    height: auto;
  }*/
}


// Special styles for iOS temporal inputs
//
// In Mobile Safari, setting `display: block` on temporal inputs causes the
// text within the input to become vertically misaligned. As a workaround, we
// set a pixel line-height that matches the given height of the input, but only
// for Safari. See https://bugs.webkit.org/show_bug.cgi?id=139848
//
// Note that as of 8.3, iOS doesn't support `datetime` or `week`.

/*@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    &.form-control {
      line-height: @input-height-base;
    }

  }
}*/

// Form control feedback states
//
// Apply contextual and semantic states to individual form controls.

/*.has-feedback {
  // Enable absolute positioning
  position: relative;

  // Ensure icons don't overlap text
  .form-control {
    padding-right: (@input-height-base * 1.25);
  }
}*/

// Feedback icon (requires .glyphicon classes)
.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2; // Ensure icon is above input groups
  display: block;
  //width: @input-height-base;
  //height: @input-height-base;
  //line-height: @input-height-base;
  text-align: center;
  pointer-events: none;
}

// Feedback states
// .has-success {
//   .form-control-validation(@state-success-text; @state-success-text; @state-success-bg);
// }
// .has-warning {
//   .form-control-validation(@state-warning-text; @state-warning-text; @state-warning-bg);
// }
// .has-error {
//   .form-control-validation(@state-danger-text; @state-danger-text; @state-danger-bg);
// }

// Reposition feedback icon if input has visible label above
/*.has-feedback label {

  & ~ .form-control-feedback {
    top: (@line-height-computed + 5); // Height of the `label` and its margin
  }
  &.sr-only ~ .form-control-feedback {
    top: 0;
  }
}*/

// Help text
//
// Apply to any element you wish to create light text for placement immediately
// below a form control. Use for general help, formatting, or instructional text.

.help-block {
  display: block; // account for any element using help-block
  margin-top: 5px;
  margin-bottom: 15px;
  transform: rotateX(90deg);
  transition: all 0.2s ease-in;
}



.has-error {
  .checkbox, .checkbox-inline, &.checkbox-inline label, &.checkbox label, .help-block {
    color: #ef4136;
  }
}