.internal-links-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  justify-content: space-between;
  gap: 10px;


  /* Hide scrollbar for webkit-based browsers */
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  /* Hide scrollbar for other browsers */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  @media (max-width: 767px) {
    overflow-x: scroll;
  }
}



.popLink-row-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.popularLinks__title {
  .header-font(28px);

  margin-bottom: 24px;
  color: var(--primary-color);

  @media (max-width: 767px) {
    .font-size(24px);
  }
}

.popLink-row-header-container a {
  color: var(--tertiary-color);
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  text-transform: capitalize;

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.show-all-popLinks.custom-button {
  background-color: none !important; /* Override background color */
  border-radius: initial !important; /* Override border radius */
  color: var(--primary-color) !important; /* Override color */
  font-size: 12px !important; /* Override font size */
  font-weight: initial !important; /* Override font weight */
  margin-top: initial !important; /* Override margin top */
  padding: 0 !important; /* Override padding */
  align-items: center;
  background: none;
  border: none;
  display: flex;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.show-all-popLinks.custom-button:hover {
  background: none !important;
  text-decoration: underline;
}

.internal-link {
  position: relative;
  height: 250px;
  min-width: 200px;
  width: 200px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  border-radius: var(--internal-links-border-radius);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  &.betsson{
    @media (max-width: 500px) {
      height: 200px;
      min-width: 150px;
      background-size: contain;
    }
  }
 
}

.overlay-poplinks {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -100;
}

.logo-poplink, .title-poplink {
  z-index: 2;
}

.logo-poplink {
  width: 50px;
  height: auto;
  text-overflow: ellipsis;

  @media (max-width: 767px) {
    width: 40px;
  }
}

.title-poplink {
  font-weight: bold;
  color: #fff;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;

  @media (max-width: 767px) {
    font-size: 16px;
    display: block;
    white-space: normal;
  }
}
