@flag-spacing: 36px;

#rizk-footer {
  color: var(--footer-text-color);
  min-height: 100px;
  overflow: hidden;
  text-align: center;
  padding: 25px 0; //margin-top:20px;
  border-top: #545454 1px solid;
  .three-stop-bggradient(var(--footer-gradient-start), var(--footer-gradient-stop), var(--footer-gradient-stop));
  transition: transform 0.5s ease-in-out;
  li {
    display: inline-block;
    padding: 0 15px;
  }

  .language-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: 1px solid #4a4848;

    > a {
      padding: 15px 17px;
      &:hover {
        .flags {
          transform: scale(1.1);
        }
      }
    }
    .flags {
      background: url('/assets/img/flags/locale-flags-small.png') no-repeat;
      background-size: cover;
      display: inline-block;
      width: 36px;
      height: 24px;
      &.fi {
        background-position: 0 0;
      }
      &.no {
        background-position: -@flag-spacing 0;
      }
      &.se {
        background-position: -2 * @flag-spacing 0;
      }
      &.de {
        background-position: -3 * @flag-spacing 0;
      }
      &.uk {
        background-position: -4 * @flag-spacing 0;
      }
      &.gb {
        background-position: -4 * @flag-spacing 0;
      }
      &.nz {
        background-position: -5 * @flag-spacing 0;
      }
      &.au {
        background-position: -6 * @flag-spacing 0;
      }
      &.ca {
        background-position: -7 * @flag-spacing 0;
      }
      &.in {
        background-position: -9 * @flag-spacing 0;
      }
      &.es {
        background-position: -10 * @flag-spacing 0;
      }
      &.is {
        background: url('/assets/img/flags/flag-is.png') no-repeat;
        background-size: 45px;
        background-position: center;
        border-radius: 3px;
      }
      &.pl {
        background: url('/assets/img/flags/POLAND.svg') no-repeat;
        background-size: 45px;
        background-position: center;
        border-radius: 3px;
      }
      &.international {
        background-position: -8 * @flag-spacing 0;
      }
    }
  }
  .footer-logos {
    @footer-logos-filter: var(--footer-logos-filter);
    @footer-logos-hover: var(--footer-logos-hover);
    @footer-logos-hover-darker: var(--footer-logos-hover-darker);

    @footer-logos-border-bottom: var(--footer-logos-border-bottom);
    border-bottom: var(--footer-logos-border-bottom);
    padding: 10px 0;

    // transform: scale(0.75);
    li {
      background-image: url('/assets/img/footer/footer-logos-colors8.png');
      height: 28px;
      margin: 4px;

      &.logo_bg_hidden {
        background-image: none;

        &:last-child {
          padding-left: 0;
        }
      }

      a {
        height: 28px;
        margin: 4px;
      }
    }

    .visa-logo {
      width: 66px;
      background-position: 0px -23px;
      &:hover {
        background-position: 0px 3px;
      }
    }

    .visa-secure-logo {
      background-image: url('/assets/img/footer/visa-secure-logo.png');
      background-size: contain;
      filter: grayscale(1) brightness(0);
      height: 28px;
      display: block;
      width: 28px;
      margin-left: 20px;

      &:hover {
        filter: none;
      }
    }

    .master-id-check-logo {
      background-image: url('/assets/img/footer/master-id-check-logo.svg');
      background-size: contain;
      filter: grayscale(1) brightness(0.6);
      background-repeat: no-repeat;
      background-position: center;
      width: 100px;
      height: 28px;
      display: block;
      margin: 0;

      &:hover {
        filter: none;
      }
    }

    .luxon-logo {
      background-image: url(/assets/img/footer/luxon_pay.svg);
      background-size: cover;
      -webkit-filter: grayscale(1) brightness(4.6);
      filter: grayscale(1) brightness(4.6);
      background-repeat: no-repeat;
      background-position: center;
      width: 100px;
      height: 28px;

      &:hover {
        filter: brightness(3);
      }
    }

    .yape-logo-optimised {
      background-image: url(/assets/img/footer/yape-logo.svg) !important;
      background-size: cover;
      -webkit-filter: grayscale(1) brightness(4.6);
      filter: grayscale(1) brightness(4.6);
      background-repeat: no-repeat;
      background-position: center;
      width: 31px;
      height: 29px;

      &:hover {
        filter: brightness(3);
      }
    }

    .apple-logo {
      background-image: url(/assets/img/footer/optimised/applepay-logo.svg);
      background-size: cover;
      -webkit-filter: brightness(0.4);
      filter: var(--footer-logos-filter) brightness(0.4);
      background-repeat: no-repeat;
      background-position: center;
      width: 50px;
      height: 20px;

      &:hover {
        filter: brightness(1);
      }
    }

    .casino-org-logo {
      background-image: url(/assets/img/footer/casino_org.svg);
      background-size: cover;
      -webkit-filter: grayscale(1) brightness(1.6);
      filter: grayscale(1) brightness(1.6);
      background-repeat: no-repeat;
      background-position: center;
      width: 100px;
      height: 28px;

      &:hover {
        filter: none;
      }
    }

    .a-bon-logo {
      width: 41px;
      background-position: -695px -27px;
      transform: scale(1.3);
      &:hover {
        background-position: -695px 0px;
      }
    }
    .air-cash-logo {
      width: 96px;
      background-position: -599px -27px;
      &:hover {
        background-position: -599px -2px;
      }
    }

    .revolut-logo {
      width: 47px;
      background-position: -821px -25px;
      &:hover {
        background-position: -821px -2px;
      }
    }
    // .much-better-logo {
    //   width: 54px;
    //   background-position: -759px -23px;
    //   &:hover {
    //     background-position: -759px 0px;
    //   }
    // }
    .interac-logo {
      background: url('/assets/img/lander/interac-2.svg') no-repeat;
      width: 32px;
      background-size: contain;
      filter: grayscale(100%);
      opacity: 0.65;
      &:hover {
        filter: grayscale(0%);
        opacity: 1;
      }
    }
    .interac-e-transfer-logo {
      width: 67px;
      height: 27px;
      background: url('/assets/img/lander/interac-e-transfer.png') no-repeat;
      background-size: contain;
      margin-left: 5px;
      margin-right: 15px;
      filter: grayscale(100%);
      opacity: 0.65;
      &:hover {
        filter: grayscale(0%);
        opacity: 1;
      }
    }
    .master-logo {
      width: 44px;
      background-position: -63px -22px;
      &:hover {
        background-position: -63px 4px;
      }
    }
    .mifinity-logo {
      width: 89px;
      background-position: -490px -90px;
      &:hover {
        background-position: -491px -61px;
      }
    }

    .neosurf {
      width: 110px;
      background-position: -572px -90px;
      &:hover {
        background-position: -572px -61px;
      }
    }

    .maestro-logo {
      width: 56px;
      background-position: -875px -24px;
      &:hover {
        background-position: -875px -1px;
      }
    }
    .paypal-logo {
      width: 82px;
      background-position: -171px -92px;
      &:hover {
        background-position: -171px 157px;
      }
    }
    .bank-transfer-logo {
      width: 60px;
      background-position: -252px -82px;
      filter: var(--footer-logos-filter);
      &:hover {
        filter: var(--footer-logos-hover);
        background-position: -252px 159px;
      }
    }
    .tsi-logo {
      width: 44px;
      background-position: -159px -26px;
      &:hover {
        background-position: -159px 210px;
      }
    }
    .skrill-logo {
      width: 50px;
      background-position: -107px -25px;
      filter: var(--footer-logos-filter);
      &:hover {
        filter: var(--footer-logos-hover);
        background-position: -107px 210px;
      }
    }
    .much-better-logo {
      background-image: url('/assets/img/footer/much-better-logo.png') !important;
      background-position: unset;
      background-size: cover;
      background-repeat: no-repeat;
      width: auto;
      filter: grayscale(1);
      &:hover {
        filter: grayscale(0);
      }
    }

    .neteller-logo {
      width: 76px;
      background-position: -203px -25px;
      filter: var(--footer-logos-filter);
      &:hover {
        background-position: -203px 211px;
      }
    }
    .trustly-logo {
      width: 77px;
      background-position: -449px -25px;
      &:hover {
        background-position: -449px 212px;
      }
    }
    .euteller-logo {
      width: 70px;
      background-position: -525px -26px;
      &:hover {
        background-position: -525px 211px;
      }
    }
    .netent-logo {
      width: 89px;
      background-position: 0px 161px;
      background-image: url('/assets/img/footer/footer-logos-colors8.png') !important;
      background-repeat: inherit !important;
      height: 32px !important;
    }
    .comodo-logo {
      width: 53px;
      background-position: -312px 125px;
      &:hover {
        background-position: -312px 155px;
      }
    }
    .rng-logo {
      width: 44px;
      background-position: -368px 122px;
      &:hover {
        background-position: -368px 155px;
      }
    }
    .ideal-logo {
      width: 46px;
      margin-left: 7px;
      background-position: 0px 0px;
    }
    .sofort-logo {
      width: 74px;
      background-position: -373px -28px;
      &:hover {
        background-position: -376px 210px;
      }
    }
    .zimpler-logo {
      width: 89px;
      background-position: -283px -28px;
      &:hover {
        background-position: -283px 211px;
      }
    }
    .poli-logo {
      width: 89px;
      background-position: -416px -88px;
      &:hover {
        background-position: -416px 155px;
      }
    }
  }

  .footer-logos2 span {
    background-image: url('/assets/img/footer/footer-logos2.png');
    background-repeat: no-repeat;
    display: inline-block;
    height: 39px;
  }
  .casinomeister {
    width: 113px;
    background-position: 0 0;
  }
  .cb-approves {
    background-image: url('/assets/img/footer/CB-APPROVES.svg') !important;
    width: 105px;
    background-position: center;
    height: 32px !important;
  }
  .casino-top-ca {
    background-image: url('/assets/img/footer/casino-top-ca.png') !important;
    width: 105px;
    background-position: center;
    background-size: contain;
  }
  .casino-top-no {
    background-image: url('/assets/img/footer/casino-top-no.png') !important;
    width: 105px;
    background-position: center;
    background-size: contain;
  }
  .bonus-finder-approves {
    background-image: url('/assets/img/footer/bonusfinder.png') !important;
    width: 105px;
    background-position: center;
  }
  .casinotopsonline {
    background-image: url('/assets/img/footer/casinotopsonline.png') !important;
    width: 100px;
    background-position: center;
  }
  .gamblejoe {
    background-image: url('/assets/img/footer/gamblejoe.png') !important;
    width: 100px;
    background-position: center;
    background-size: contain;
  }
  .casino-professor {
    background-image: url('/assets/img/footer/casino-professor.png') !important;
    width: 36px;
    background-position: center;
    background-size: contain;
  }
  .lcb {
    width: 74px;
    background-position: -125px 0;
  }

  .mga {
    width: 108px;
    background-position: -204px 0;
  }
  .lotteriinspektionen {
    width: 58px;
    background-position: -354px 0;
  }
  .curacao {
    width: 30px;
    background-position: -324px 0;
    background: none !important;
  }
  .footer-logos3 {
    transform: scale(0.7);
    padding-bottom: 40px;
    span {
      background-repeat: no-repeat;
      background-position-y: center;
      display: flex;
      justify-content: space-evenly;
      height: 74px;
    }

    .gamble-aware-logo {
      background-image: url(/assets/img/footer/be-gamble-aware.png);
      background-size: contain;
      width: 191px;
      filter: var(--footer-logos-filter);
    }
    .gambling-commission-logo {
      background-image: url(/assets/img/footer/gambling-commission.png);
      background-size: contain;
      width: 180px;
    }
    .gamstop-logo {
      background-image: url(/assets/img/footer/gam-stop.svg);
      background-size: contain;
      width: 160px;
    }
    .plus-18-logo {
      background-image: url(/assets/img/footer/plus_18_pe.svg);
      background-size: contain;
      width: 70px;
    }
    span.footer-logo-odr {
      background-image: url('/assets/img/footer/footer-logo-odr.svg');
      background-position: center;
      background-size: contain;
      width: 144px;
    }
    span.libro-logo {
      background: url('/assets/img/footer/libro-logo-pe.png') no-repeat;
      background-position: 50%;
      background-size: cover;
      width: 130px;
    }
    span.footer-logo-GGL {
      background-image: url('/assets/img/footer/footer-old-gcl.svg');
      background-size: contain;
      width: 220px;
      height: 98px !important;
    }
    @media (max-width: @menu-switch-breakpoint) {
      span.footer-logo-GGL {
        margin-bottom: 15px;
      }
    }
    span.mga-logo {
      background-image: url('/assets/img/footer/MGA.svg?v2') !important;
      width: 120px;
      background-position: center;
      background-size: cover;
    }
    span.casino-org-logo {
      background-image: url(/assets/img/footer/casino_org.svg) !important;
      width: 164px;
      background-position: center;
      background-size: contain;
    }
    span.lotteriinspektionen {
      background-image: url('/assets/img/footer/sv_logo.png') !important;
      width: 106px;
      height: 60px !important;
      background-position: center;
    }
    span.arn-logo {
      background-image: url('/assets/img/footer/arn.png') !important;
      width: 200px;
      height: 60px !important;
      background-position: center;
    }
    span.mincetur-logo {
      background-image: url('/assets/img/footer/Mincetur.svg') !important;
      width: 300px;
      height: 60px;
      background-position: center;
      background-size: cover;
    }
    span.gam-care-logo-pe {
      width: 48px;
      height: 60px !important;
      background-position: center;
      background-image: url('/assets/img/footer/GameCareLogo.svg') !important;
      background-position: center;
      background-size: cover;
    }

    span.gx4-logo {
      width: 48px;
      height: 60px !important;
      background-position: center;
      background-image: url('/assets/img/footer/Gx4Logo.svg') !important;
    }

    span.iso-logo {
      width: 48px;
      height: 60px !important;
      background-position: center;
      background-image: url('/assets/img/footer/IsoLogo.svg') !important;
    }

    span.egba-logo {
      width: 48px;
      height: 60px !important;
      background-position: center;
      background-image: url('/assets/img/footer/EGBA_Logo_red_RGB_01.svg') !important;
    }
    //body.es & {
    //    transform: scale(1);
    //}
  }
  .mas-eighteen-logo {
    width: 72px;
    background-size: contain;
    background-position: center;
    background-image: url('/assets/img/footer/logo_mas_18.png') !important;
  }
  .bandera-logo {
    width: 200px;
    background-size: contain;
    background-position: center;
    background-image: url('/assets/img/footer/bandera.png') !important;
  }
  .ga-logo {
    width: 72px;
    background-size: contain;
    background-position: center;
    background-image: url('/assets/img/footer/ga_logo.png') !important;
  }
  .jugar-bien-logo {
    width: 72px;
    background-size: contain;
    background-position: center;
    background-image: url('/assets/img/footer/logo_JugarBien.png') !important;
  }
  .autoprohibicion-logo {
    width: 72px;
    background-size: contain;
    background-image: url('/assets/img/footer/logo_Autoprohibicion.png') !important;
  }

  .gam-care-logo {
    width: 48px;
    background-position: center;
    background-image: url('/assets/img/footer/GameCareLogo.png') !important;
  }

  .rg-week-logo {
    width: 118px;
    height: 58px !important;
    background-position: center;
    background-image: url('/assets/img/footer/RGWeekLogo.png') !important;
  }

  .time-to-think-logo {
    width: 118px;
    height: 58px !important;
    background-position: center;
    background-image: url('/assets/img/footer/timetothinklogo.svg') !important;
  }

  .gam-anon-uk-irl-logo {
    width: 148px;
    background-position: -62px 0px;
  }
  .ecogra-logo {
    width: 250px;
    background-position: center;
    background-size: contain;
    background-image: url('/assets/img/footer/ecogra.png') !important;
  }

  .ecogra-safe-fair {
    width: 250px;
    background-position: center;
    background-size: contain;
    background-image: url('/assets/img/footer/ecogra-safe-fair.png') !important;
  }
  .gam-anon-logo {
    width: 100px;
    background-position: center;
    background-size: cover;
    background-image: url('/assets/img/footer/GamblersAnon.svg') !important;
  }
  .hjel-logo {
    width: 200px;
    background-position: center;
    background-size: cover;
    background-image: url('/assets/img/footer/Hjelpelinjen.png') !important;
  }

  .plus-18-logo-pe {
    width: 100px;
    height: 80px !important;
    background-position: center;
    background-size: cover;
    background-image: url('/assets/img/footer/plus_18_pe.svg') !important;
  }
  .plus-19-logo {
    width: 100px;
    height: 100px !important;
    background-position: center;
    background-size: cover;
    background-image: url('/assets/img/footer/19-plus.png') !important;
  }
  .plus-20-logo {
    width: 100px;
    height: 100px !important;
    background-position: center;
    background-size: cover;
    background-image: url('/assets/img/footer/20-plus.png') !important;
  }
  .plus-21-logo {
    width: 100px;
    height: 100px !important;
    background-position: center;
    background-size: cover;
    background-image: url('/assets/img/footer/21-plus.png') !important;
  }
  .croatia-mfin-logo {
    width: 60px;
    background-position: -1410px;
  }
  .croatia-mfin-text {
    background-image: none !important;
    color: #fff;
    height: 54px !important;
    vertical-align: bottom;
    font-size: 22px;
  }

  .stodlinjen-logo {
    width: 165px;
    background-position: -1249px 0px;
  }
  .footer-links {
    margin: 0;
    border-bottom: 1px solid #4a4848;
    border-top: 1px solid #4a4848;
    padding: 15px 0 15px 0;

    a {
      line-height: 30px;
      text-transform: uppercase;
      letter-spacing: @letr-spacing-large;
      color: @pinkish-grey;
      white-space: nowrap;
      &:hover {
        color: var(--footer-links-hover);
      }
      &.menu-item-active {
        color: var(--tertiary-color);
      }
    }
  }

  .footer-social-icons {
    display: flex;
    color: white;
    justify-content: center;
    padding: 15px 0;
    border-bottom: 1px solid #4a4848;
    gap: 20px;
    align-items: center;

    &__item {
      width: 30px;
      height: 30px;

      &--fb {
        background-image: url('/assets/img/Facebook.svg');

        &:hover {
          background-image: url('/assets/img/facebook-hover.png');
          background-size: cover;
        }
      }

      &--insta {
        background-image: url('/assets/img/Instagram.svg');

        &:hover {
          background-image: url('/assets/img/instagram-hover.png');
          background-size: cover;
        }
      }
    }
  }
  .footer-text {
    margin: 20px auto 0;
    max-width: 1200px;
    p {
      padding: 0 15px;
    }
    a {
      color: @darkness-grey;
      &:hover {
        color: @pinkish-grey;
      }
    }
  }
  .rizk-logo-footer {
    margin-bottom: 20px;
  }
  .cookie-disclaimer {
    position: fixed;
    bottom: 0%;
    right: 0%;
    z-index: 101;
    background: @dark-grey;
    color: @darkness-grey;
    text-align: center;
    width: 100%;
    overflow: hidden;
    -webkit-box-shadow: 0 0 5px black;
    -moz-box-shadow: 0 0 5px black;
    box-shadow: 0 0 5px black;
    padding-bottom: 15px;
    transform: translateY(100%);
    transition: all 0.4s ease-in;
    p {
      margin-bottom: 0;
      & a {
        text-decoration: underline;
        color: @darkness-grey;
      }
      & .disclaimer-button {
        color: @dark-grey;
      }
    }
    &-button {
      margin-left: 10px;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    &-read-more {
      color: @button-yellow;
      background-color: transparent;
      &:hover {
        color: @button-yellow;
        text-decoration: underline;
      }
    }
  }
  .footer-cta {
    display: none;
  }

  .none {
    background-image: none !important;
    display: none !important;
  }
  @media (max-width: 512px) {
    .footer-text {
      body.hr & {
        display: block;
      }
    }
    .footer-logos li {
      margin: 0 5px;
    }
  }
  @media (max-width: @menu-switch-breakpoint) {
    .footer-cta {
      display: block;
    }
    #rizk-footer {
      padding-bottom: @mobile-drawer-menu-height;
    }
  }
}

.sr {
  .footer-logos {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 1rem;
    justify-content: center;
    padding: 1rem;
    width: 100%;
    li {
      height: 31px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .cornershop-logo {
    .logo-mixin(56px,'/assets/img/footer/cornershop-white-sm.png', grayscale(1) brightness(0.5));
  }
}

.hr{
  .apple-logo {
    width:64px !important;
    height:25px !important;
  }
  .skrill-logo {
    width: 84px !important;
    height: 24px !important;
    background: url('/assets/img/lander/skrill.png') no-repeat !important;
    background-size: contain !important;
    background-position-y: center ;
    filter: grayscale(1) brightness(1);

    &:hover {
      filter: none;
    }
  }
  @media (max-width: 512px) {
    .footer-logos {
      display: table;
      gap: 12px; // Reduce gap for smaller screens
    }
    .apple-logo,
    .skrill-logo {
      width: 50px !important; // Smaller width for mobile
      height: 20px !important; // Smaller height for mobile
    }
  }
}