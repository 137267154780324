.homepageBanner {
  .homepage_banner_div {
    position: relative;
    width: 100%;
    height: 300px;
    margin: 0 auto;
    background-color: var(--homepage-banner-bg);
    @media (max-width: 767px) {
      height: 208px;
    }
  }
  .homepage_banner_image,
  .homepage_banner_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

    .homepage_banner_text {
      padding: 32px 40px;
      width: 50%;
      text-wrap: pretty;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 767px) {
        width: calc(100% - 75px);
        min-height: 208px;
        padding: 16px;
      }

      .homepage-banner-title {
        font-size: 32px;
        color: var(--homepage-banner-text);
        margin-bottom: 8px;
        text-transform: none;
        @media (max-width: 767px) {
          font-size: 20px;
          margin-bottom: 16px;
        }
      }

      .homepage-banner-text {
        color: var(--homepage-banner-text);
        margin-bottom: 24px;
        font-size: 14px;
        @media (max-width: 767px) {
          margin-bottom: 16px;
        }
      }

      #homepage-banner-button {
        height: 56px;
        width: max-content;
        min-width: 129px;
        border-radius: 4px;
        background-color: var(--primary-button-bg);
        margin-top: 0;
        padding: 20px;
        font-size: 14px;
        color: var(--homepage-banner-text);
        @media (max-width: 767px) {
          height: auto;
          padding: 10px;
          min-width: 78px;
          font-size: 14px;
        }
      }

      #homepage-banner-button:hover {
        background-color: var(--primary-button-bg-hover);
      }
    }
}
