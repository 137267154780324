.logo-mixin(@width, @image, @footer-logos-filter, @footer-logos-hover: brightness(1), @background-size: cover) {
  width: @width;
  background-image: url(@image) !important;
  background-size: @background-size;
  background-position: center;
  filter: @footer-logos-filter;

  &:hover {
    filter: @footer-logos-hover;
  }
}
.logo-size(@width,@height) {
  width: @width;
  height: @height;
}
.logo-size(@width,@height) {
  width: @width;
  height: @height;
}
.logo-size(@width,@height) {
  width: @width;
  height: @height;
}

.footer-logos {
  .visa-logo-optimised {
    width: 65px;
    background-image: url(/assets/img/footer/optimised/visa.svg) !important;
    background-size: cover;
    background-position: center;
    filter: var(--footer-logos-filter) brightness(1);

    &:hover {
      filter: var(--footer-logos-hover);
    }
  }
  .PagoDirectoLogo {
    width: 65px;
    background-image: url(/assets/img/footer/optimised/PagoDirectoLogo.svg) !important;
    background-size: cover;
    background-position: center;
    filter: brightness(0.5);

    &:hover {
      filter: brightness(1);
    }
  }
  .PagoEfectivoLogo {
    width: 65px;
    background-image: url(/assets/img/footer/optimised/PagoEfectivoLogo.svg) !important;
    background-size: cover;
    background-position: center;
    filter: brightness(0.5);

    &:hover {
      filter: brightness(1);
    }
  }
  .BBVA_2019-logo-optimised {
    width: 65px;
    background-image: url(/assets/img/footer/optimised/BBVA_2019.svg) !important;
    background-size: cover;
    background-position: center;
    filter: var(--footer-logos-filter);

    &:hover {
      filter: var(--footer-logos-hover);
    }
  }
  .via-bcp-logo-vector-logo-optimised {
    width: 65px;
    background-image: url(/assets/img/footer/optimised/via-bcp-logo-vector.svg) !important;
    background-size: cover;
    background-position: center;
    filter: var(--footer-logos-filter);

    &:hover {
      filter: var(--footer-logos-hover);
    }
  }
  .scotiabank-5-logo-optimised {
    width: 65px;
    background-image: url(/assets/img/footer/optimised/scotiabank-5.svg) !important;
    background-size: cover;
    background-position: center;
    filter: var(--footer-logos-filter);

    &:hover {
      filter: var(--footer-logos-hover);
    }
  }
  .safetypay-logo-optimised {
    width: 65px;
    background-image: url(/assets/img/footer/optimised/safetypay-logo.png) !important;
    background-size: cover;
    background-position: center;
    filter: var(--footer-logos-filter);

    &:hover {
      filter: var(--footer-logos-hover);
    }
  }
  .Paysafecard_logo-logo-optimised {
    width: 65px;
    background-image: url(/assets/img/footer/optimised/Paysafecard_logo.svg) !important;
    background-size: cover;
    background-position: center;
    filter: var(--footer-logos-filter);

    &:hover {
      filter: var(--footer-logos-hover);
    }
  }
  .Interbank_logo-logo-optimised {
    width: 65px;
    background-image: url(/assets/img/footer/optimised/Interbank_logo.svg) !important;
    background-size: cover;
    background-position: center;
    filter: var(--footer-logos-filter);

    &:hover {
      filter: var(--footer-logos-hover);
    }
  }
  .mifinity-logo-optimised {
    width: 90px;
    background-image: url(/assets/img/footer/optimised/mifinity.svg) !important;
    background-size: cover;
    background-position: center;
    filter: grayscale(1);

    &:hover {
      filter: brightness(1);
    }
  }
  .neosurf-logo-optimised {
    width: 65px;
    background-image: url(/assets/img/footer/optimised/neosurf.svg) !important;
    background-size: cover;
    background-position: center;
    filter: grayscale(1);

    &:hover {
      filter: brightness(1);
    }
  }
  .mastercard-logo-optimised {
    width: 65px;
    background-image: url(/assets/img/footer/optimised/mastercard.svg) !important;
    background-size: cover;
    filter: var(--footer-logos-filter) brightness(0.5);

    &:hover {
      filter: var(--footer-logos-hover-darker);
    }
  }
  .neteller-logo-optimised {
    width: 65px;
    background-image: url(/assets/img/footer/optimised/neteller.svg) !important;
    background-size: cover;
    background-position: center;
    filter: var(--footer-logos-filter) brightness(0.5);
    &:hover {
      filter: var(--footer-logos-hover);
    }
  }
  .bank-transfer-logo-optimised {
    width: 65px;
    background-image: url(/assets/img/footer/optimised/bank-transfer.svg) !important;
    background-size: cover;
    background-position: center;
    filter: var(--footer-logos-filter) brightness(0.5);
    &:hover {
      filter: var(--footer-logos-hover);
    }
  }
  .paysafe-logo-optimised {
    width: 100px;
    background-image: url(/assets/img/footer/optimised/paysafe.svg) !important;
    background-size: cover;
    background-position: center;
    filter: grayscale(1);

    &:hover {
      filter: brightness(1);
    }
  }
  .ecopayz-logo-optimised {
    width: 70px;
    background-image: url(/assets/img/footer/optimised/ecopayz.svg) !important;
    background-size: cover;
    background-position: center;
    filter: grayscale(1);

    &:hover {
      filter: brightness(1);
    }
  }

  .altapay-logo {
    .logo-mixin(105px, '/assets/img/footer/altapaygroupvoucher-white.png', grayscale(1) brightness(2.5));
  }
  .oktopay-logo {
    .logo-mixin(90px, '/assets/img/footer/oktopaycash.svg', grayscale(1) brightness(1.5), brightness(1), 87px);
    background-repeat: no-repeat;
  }
  .cornershop-logo {
    .logo-mixin(56px, '/assets/img/footer/cornershop-white-sm.png', grayscale(1) brightness(0.5));
  }
  .nis-gasprom-logo {
    .logo-mixin(130px, '/assets/img/footer/nis-gasprom.svg', grayscale(1) brightness(0.5));
  }
  .petrol-logo {
    .logo-mixin(105px, '/assets/img/footer/petrol.svg', grayscale(1) brightness(0.5));
  }
}

.sr {
  .footer-logos {
    .visa-logo-optimised {
      width: 71px;
      height: 23px !important;
      background-image: url(/assets/img/footer/visa.png) !important;
      background-size: contain;
    }
    .mastercard-logo-optimised {
      width: 52px;
      height: 32px !important;
      background-image: url(/web/assets/img/footer/optimised/mastercard.png) !important;
    }
    .dinacard {
      width: 65px;
      height: 30px !important;
      background-image: url(/assets/img/footer/dinacard-new.png) !important;
      background-size: cover;
      filter: grayscale(1) brightness(2);

      &:hover {
        filter: brightness(1);
      }
    }
    .bank-transfer-logo-optimised {
      width: 35px;
      height: 39px !important;
      background-image: url(/web/assets/img/payment_methods/banktransfer-color.png) !important;
    }
    .altapay-logo {
      .logo-mixin(114px, '/assets/img/footer/altapaygroupvoucher-white.png', grayscale(1) brightness(2.5));
    }
    .oktopay-logo {
      .logo-mixin(128px, '/assets/img/footer/oktopaycash.svg', grayscale(1) brightness(1.5), brightness(1), 129px);
    }
    .nis-petrol-logo {
      .logo-mixin(80px, '/assets/img/footer/nis-petrol-logo.png', grayscale(1) brightness(1));
    }
    .gazprom-logo {
      .logo-mixin(57px, '/assets/img/footer/gazprom-logo.png', grayscale(1) brightness(1));
    }
    .cornershop-logo {
      .logo-mixin(56px,'/assets/img/footer/cornershop-white-sm.png', grayscale(1) brightness(0.5));
    }
  }
}

.hr {
  .footer-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px;
    flex-wrap: wrap;
  }
  .visa-logo-optimised {
    .logo-mixin(65px,'/web/assets/img/footer/visa.png', grayscale(1) brightness(1.5));
    height: 21px !important;
  }
  .revolut-logo-optimised {
    .logo-mixin(96px,'/web/assets/img/footer/revolutgrey.png', grayscale(1) brightness(1.5));
    height: 21px !important;
    &:hover {
      filter: brightness(5);
    }
  }
  .aircash-logo-optimised {
    .logo-mixin(102px,'/web/assets/img/footer/aircash.png', grayscale(1) brightness(1.5));
    height: 22px !important;
  }
  .mastercard-logo-optimised {
    width: 70px;
  }
  .neteller-logo-optimised {
    .logo-size(115px,74px);
  }
  .aircash-logo-optimised {
    .logo-size(136px,21px);
  }
  .bank-transfer-logo-optimised {
    .logo-size(76px,38px) !important;
  }
  @media (max-width: 512px) {
    .footer-logos {
      display: flex;
      gap: 12px;
    }

    .mastercard-logo-optimised,
    .neteller-logo-optimised,
    .bank-transfer-logo-optimised {
      .logo-size(50px,20px) !important;
    }
    .aircash-logo-optimised {
      .logo-size(82px,13px) !important;
    }
    .revolut-logo-optimised {
      .logo-size(70px,15px) !important;
    }
    .visa-logo-optimised {
      .logo-size(48px,15px) !important;
    }
  }
}
