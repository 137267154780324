.all-content {
  position:absolute;
  width: calc(100% ~"-" @side-menu-width);
  right: 0;
  top: 0;
  //height:100%;
  //min-height:100%;
}

#mobile-menu {
  background:#D63227;
}