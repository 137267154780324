.rizk-progress-bar-container {
  width:100%;
  display:inline-block;
  vertical-align: middle;
  position:relative;
}

.rizk-level-up {
  background:@brand-yellow;
}

.progress-container {
  overflow: hidden;
  height: 8px;
}

.rizk-progress-percent {
  margin: 8px 0px 0 10px;
  color: @brand-yellow;
  display:inline-block;
  vertical-align: middle;
}

.rizk-progress-bar-up {
  background: @brand-black;
  border: 2px solid var(--side-menu-progress-up);
  border-radius: 8px;
  height:16px;
}

.rizk-progress-bar-down {
  background: @brand-black;
  border: 2px solid var(--side-menu-progress-down);
  border-radius: 8px;
  height:16px;
  margin-top:-8px;
}

.indicator-container {
  position:absolute;
  overflow:hidden;
  width:100%;
  height:16px;
  border-radius: 8px;
  border: 2px solid transparent;
  z-index:6;
}

.rizk-progress-indicator {
  width: 100%;
  height: 100%;
  top: 0;
  background: #f6e103;
  background:url("../img/rizk-bar-bg.png");
  background: var(--powerbar-progress);
}

.rizk-progress-black {
  width: 100%;
  height: 100%;
  position:absolute;
  backface-visibility:hidden;
  top: 0;
  background: #000;
}

@keyframes progress-fx-frames {
  from {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
}

.rizk-progress-fx-container {
  position: relative;
  z-index: 20;
  //border: 2px solid transparent;
  //border-radius: 8px;
  //width: 100%;
  height: 100%;
  top: 0;
  width:100%;
  overflow:hidden;
}

.rizk-progress-fx-highlight {
  display:none;
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+50,ffffff+100&0+0,0.6+50,0+100 */
background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 50%, rgba(255,255,255,0) 100%); /* FF3.6+ */
background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.6)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 50%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 50%,rgba(255,255,255,0) 100%); /* IE10+ */
background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 50%,rgba(255,255,255,0) 100%); /* W3C */

  width:100%;
  height:12px;
  transform: translateX(-100%);
  animation: progress-fx-frames 1s linear 0.5s infinite;
}

.rizk-progress-fx-flash {
  width:100%;
  height:100%;
  background:white;
  position:absolute;
  opacity:0;
  z-index:10;
  backface-visibility: hidden;
  transition: opacity 0.25s linear;
}

#percent-value {
  padding-top:20px;
  color:#7f2024;
}

/*.progress-loader {
    animation: loader-progress 2s infinite;
}

@keyframes loader-progress {
  from {width: 0%;}
  to {width: 100%;}
}*/

