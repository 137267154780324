.rizk-border-radius {
  border-radius: 5px;
}

.bggradient(@start, @stop) {
  background: @stop / 2 + @start / 2;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, @stop),
    color-stop(1, @start)
  );
  background: -ms-linear-gradient(bottom, @stop, @start);
  background: linear-gradient(0deg, @stop, @start);
}

.three-stop-bggradient(@start, @mid, @stop) {
  background: linear-gradient(0deg, @stop, @mid, @start);
}

.background-gradient(@start, @stop) {
  .bggradient(@start, @stop);
}

.header-font(@fontSize) {
  font-family: var(--header-font), sans-serif;
  font-weight: var(--header-font-weight, inherit);
  .font-size(@fontSize);
}

.font-size(@fontSize) {
  font-size: calc(@fontSize / var(--header-font-ratio, 1));
}

.green-heading {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .background-gradient(#78c758, #009a3c);
}

.valign-top {
  vertical-align: top;
}

#seo-content-box {
  font-size: 14px;
  border-top: 1px solid var(--border-color);
  color: var(--primary-color);
  background: var(--body-background-color);

  :first-child {
    margin-top: 0px;
  }

  :last-child {
    margin-bottom: 0px;
  }

  a{
    color: var(--tertiary-color);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    text-transform: uppercase;
    margin: 20px 0px 10px 0px;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.6em;
  }

  h3 {
    font-size: 1.3em;
  }

  h4 {
    font-size: 1.1em;
  }

  h5 {
    font-size: 1em;
  }

  h6 {
    font-size: 0.8em;
  }
}
