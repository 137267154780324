.highlight-box {
    background: #ef4136;
    display: flex;
    flex-flow: column;
    aside,
    footer {
        flex: 1 100%;
        flex-direction: column;
    }
    aside {
        padding: 24px;
        .type {
            font-size: 24px;
            margin-bottom: 8px;
        }
        .headline {
            margin-bottom: 10px;
            font-size: 44px;
        }
        .rizk-btn {
            margin-bottom: 20px;
            font-size: 28px;
            width: 100%;
        }
    }
    footer {
        background: #212121;
        text-align: left;
        padding: 16px 24px;
    }
    .lander-bottom-item {
        display: flex;
        margin-bottom: 14px;
        margin-top: 14px;
        color: @brand-white;
        img {
            max-width: 32px;
            max-height: 28px;
            margin-right: 12px;
        }
    } // tablets and such
    @media screen and (min-width: 640px) {
        flex: 0 50%;
        flex-direction: row;
        aside {
            flex: 0 67%;
        }
        footer {
            justify-content: space-around;
            flex: 0 33%;
        }
        footer .lander-bottom-item {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }
    @media screen and (min-width: @menu-switch-breakpoint) {
        flex-direction: column;
    }
    @media screen and (min-width: 1100px) {
        position: absolute;
        top: 146px;
        left: 46px;
        width: 50%;
        max-width: 550px;
        footer .lander-bottom-item {
            margin-bottom: 20px;
            margin-top: 20px;
        }
    }
}