body {
  font-family: 'Figtree', Figtree-fallback;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 20px;
  letter-spacing: @letr-spacing-small;
  font-weight: 800;
  line-height: 1.1;
}

h2 {
  font-size: calc(30px / var(--header-font-ratio, 1));
}
@font-face {
  font-family: 'League Gothic';
  src: url(../../assets/webfonts/LeagueGothic-Regular.woff2) format('woff2');
}
@font-face {
  font-family: 'League-Gothic-fallback';
  src: local('Arial');
  size-adjust: 65%;
}
@font-face {
  font-family: 'Figtree';
  src: url(../../assets/webfonts/Figtree-Regular.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Figtree';
  src: url(../../assets/webfonts/Figtree-Italic.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Figtree';
  src: url(../../assets/webfonts/Figtree-Medium.woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url(../../assets/webfonts/Figtree-Black.woff2) format('woff2');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Figtree-fallback';
  src: local('Arial');
  size-adjust: 95%;
}

.gibson-light {
  font-weight: 300;
}
.gibson-semi-bold {
  font-weight: 600;
}
.gibson-bold {
  font-weight: 700;
}
.league-gothic, .league {
  text-transform: uppercase;
}

.league-gothic-italic,
.league-italic {
  .league;
  font-style: italic;
}

.color-white {
  color: var(--account-primary-color);
}
.color-red {
  color: @brand-red;
}
.color-green {
  color: var(--brand-green);
}
.color-yellow {
  color: var(--tertiary-color);
}
.color-grey {
  color: var(--account-tertiary-color);
}
.color-black {
  color: @brand-black;
}

.tertiary-color {
  color: var(--tertiary-color);
}

// Alignment
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
